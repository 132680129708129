import React from "react"
import RegionsImg from "../../../img/regions-in-australia-where-you-can-purchase-neosurf-prepaid-voucher.jpg"
const WhereToPurchasePage = () => {
  return (
    <div className="section section-light" id="where-to-purchase">
      <h2>Where to purchase Neosurf Vouchers in Australia?</h2>
      <hr />
      <p>
        You can buy neosurf vouchers from thousands of locations all around
        Australia. It doesn’t matter if you live in a big city or a cozy rural
        town, Neosurf has you covered. Just click on the following link & select
        Australia. Choose your region & city and you will find all point of
        sales where you can buy neosurf vouchers, from convenience stores,
        supermarkets, petrol & service stations, to newsstands, kiosks, or
        internet cafes.
      </p>
      <img
        src={RegionsImg}
        alt="Regions in Australia where you can purchase Neosurf prepaid voucher"
      />
    </div>
  )
}

export default WhereToPurchasePage
