import React from "react"

const Footer = () => {
  return (
    <div className="footer">
      <p>Copyright © 2022 Buy Neosurf | All Rights Reserved</p>
    </div>
  )
}

export default Footer
