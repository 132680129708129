import React from "react"
import OnlinePaymentsWorldwideImg from "../../../img/online-payments-wordwide.png"

const NeosurfWorldwidePage = () => {
  return (
    <div className="section section-dark" id="neosurf-worldwide">
      <h2>Neosurf Worldwide</h2>
      <hr />
      <p id="shrinked-text">
        Don’t worry if you live elsewhere. There are literally countless options
        to buy neorsurf vouchers for you too. Even for those unlucky enough to
        be located in a country with a restrictive online payment system. The
        process is the same: access this{" "}
        <a href="http://www.neosurf.com/en_GB/application/" target="_blank">
          webpage
        </a>{" "}
        findcard and search for your Neosurf near you.
      </p>
      <p id="shrinked-text">
        Choose your country and exact location and head to the nearest place
        specified on the map. If your options are limited, you can always try
        the online option. Access Neosurf Online and buy vouchers from an
        authorized online retailer.{" "}
        <b>The possibilities are endless with Neosurf!</b>
      </p>
      <img src={OnlinePaymentsWorldwideImg} alt="Online Payments Wordwide" />
    </div>
  )
}

export default NeosurfWorldwidePage
