import React from "react"
import PinCodeImg from "../../../img/10-digit-pin-code.png"
import OnlineCasinoImg from "../../../img/online-casino-deposit-with-neosurf-payment-method.jpg"

const SimpleSecure = () => {
  return (
    <div className="section section-light" id="simple-secure">
      <h2>Simple & Secure Online Payments</h2>
      <hr />
      <p>
        At purchase, you will receive a code that you can use for a variety of
        online payments. Depending on what you chose, the voucher will be worth
        15 AUD, 30 AUD, 50 AUD, 100 AUD, or the equivalent in your local
        currency. You may also find the possibility to buy minor Neosurf
        vouchers worth 10 and 20 AUD or the equivalent in your local currency.
      </p>
      <img src={PinCodeImg} alt="10-digit pin code" />
      <p>
        All vouchers have a <b>unique 10-character PIN code</b> that you can use
        every time you make an online payment. Just enter the neosurf code in
        the same way you would enter the details of your credit card. If you
        don't want to spend the whole voucher, you can do that. You can always
        transfer what's left to another voucher, up to a maximum of 250 AUD or
        equivalent in your local currency.
      </p>
      <p>
        If you aren't sure how much you have actually left on a specific
        voucher, you can access my Neosurf Card service, enter the PIN code and
        have a look at your available credit or transfer balance.
      </p>
      <p>
        <b>
          Neosurf is definitely a much simpler choice, compared to credit cards
          or the most popular e-wallets out there.
        </b>
      </p>

      <h2>Perfect for Online Casinos & Gaming</h2>
      <hr />
      <p>
        Neosurf may very well be the perfect solution for online{" "}
        <b>casinos deposits & gambling</b> without any fuss whatsoever. With
        Neosurf, you can now deposit as easily as you would in a top-tier live
        casino: exchange your cash with chips or credit and start playing your
        favorite games & slot machines in no time. Online, it's even faster:
        choose the Neosurf payment method, enter the PIN code(s) and, in a
        matter of seconds, your account balance will be up for the challenge.
        Moreover, Neosurf gives you the liberty to make perfectly secure
        payments.
      </p>
      <img
        src={OnlineCasinoImg}
        alt="Online Casino Deposit with Neosurf payment method"
      />
      <p>
        If you want to avoid paying with your everyday credit card or simply you
        don't want to disclose sensitive information regarding your bank
        account, you should definitely choose Neosurf. No strings attached! Just
        a 10-character PIN code and you are now free to{" "}
        <b>play roulette, blackjack, baccarat, poker, or slots,</b> in the
        comfort of your home.
      </p>
    </div>
  )
}

export default SimpleSecure
