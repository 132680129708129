import React from "react"
import FAQImg from "../../../img/frequently-asked-questions.jpg"
const FAQ = () => {
  return (
    <div className="section section-light" id="faq">
      <h2>Frequently Asked Questions</h2>
      <hr />
      <img src={FAQImg} alt="Questions and information" />
      <p>
        Below you will find all the necessary information you may ever need
        about Neosurf.
      </p>
      <h3>What Are Neosurf Vouchers?</h3>
      <hr />
      <p>
        Neosurf vouchers are so-called scratchcards that can help you make
        payments online, in a fast & secure way. These vouchers contain their
        actual value - standardized on a global scale with 15, 30, 50, and 100
        being the most common denominators - plus a hidden{" "}
        <b>10-character PIN code.</b> To pay online, access your favorite online
        retailer or operator and enter the neosurf code in the predefined
        three-boxed area.
      </p>
      <h3>How Can I Purchase Neosurf?</h3>
      <hr />
      <p>
        Be sure to access this{" "}
        <a
          href="https://www.neosurf.com/en_GB/application/findcard?layout=no"
          target="_blank"
        >
          page
        </a>
        , enter your country, region, and city, and the map will reveal all
        points of sale from where you can buy <b>Neosurf vouchers</b>. Head to
        the one near you and ask for a Neosurf voucher. No need for an ID, no
        questions asked.
      </p>
      <h3>Can I Buy Neosurf Vouchers Online?</h3>
      <hr />
      <p>
        Yes, you can. You can access <b>Neosurf Online</b> and browse the list
        of Neosurf Authorized Resellers (NAR), sorted alphabetically, by
        country. Don't worry if you can't find your country in the list. Since
        they operate online, the listed NARs may be able to assist you if you
        contact them.
      </p>
      <p>
        To make things even easier, you can open an account at myneosurf, a
        trending e-wallet where you can deposit using the more traditional
        methods like credit card or bank transfer.
      </p>
      <h3>Can I Buy Neosurf With PayPal Or Paysafecard?</h3>
      <hr />
      <p>
        There are websites advertising the option of buying Neosurf PIN codes
        with <b>either PayPal or Paysafecard</b>, but those websites aren't
        Neosurf legal partners. Therefore, there is a risk of purchasing used
        codes and losing your money. As a result, you recommend avoiding such
        websites and just using the official partners found here or just using
        myneosurf e-wallet service. For the latest e-wallet applications and the
        top-rated digital wallets, you will find more information at{" "}
        <a href="https://www.getewallet.com/" target="_blank">
          GeteWallet Website.
        </a>
      </p>
      <h3>What Are The Fees For Using Neosurf?</h3>
      <hr />
      <p>
        The <b>Neosurf vouchers are basically free of charge.</b> If however,
        the voucher's currency denominator is different than the currency you
        pay for that particular voucher, Neosurf will charge you according to
        the exchange rate plus and exchange fee of 2% (3% for the Polish Zloty)
      </p>
      <p>
        You can use the same PIN code for as long as you want, but one year
        after the initial purchase or 6 months after the last use, Neosurf will
        deduct an inactivity fee worth 2 EUR or equivalent, per month.
      </p>
      <p>
        For the myneosurf e-wallet service, depositing with a Neosurf voucher
        will cost you 4.5% in fees. The other methods - VISA, MasterCard, debit
        card, bank transfer, or merchant payout - are free of charge. For
        withdrawals, if you have a Neosurf card, you will be charged 2% per
        withdrawal. The fee via bank transfer is 1.5%, while the other
        withdrawal options - Neosurf merchants or vouchers - are free of charge.
      </p>
    </div>
  )
}

export default FAQ
