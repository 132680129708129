import React from "react"
import {
  Home,
  NeosurfWorldwide,
  WhereToPurchase,
  SimpleSecure,
  NeosurfCasinos,
  FAQ,
} from "../components/home/pages/index"
import Navbar from "../components/home/Navbar"
import Footer from "../components/home/Footer"
import "../styles/home.css"
import Helmet from "react-helmet"
import Favicon from "../img/favicon.ico"

export default () => (
  <>
    <Helmet>
      <title>Buy Neosurf Voucher Online – Australia & Worldwide</title>
      <meta
        name="title"
        content="Buy Neosurf Voucher Online – Australia & Worldwide"
      />
      <meta
        name="description"
        content="Looking to buy Neosurf vouchers in Australia or Worldwide? Check Buy Neosurf online website for a quick & easy solution."
      />
      <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
    </Helmet>
    <Navbar />
    <Home />
    <WhereToPurchase />
    <NeosurfWorldwide />
    <SimpleSecure />
    <NeosurfCasinos />
    <FAQ />
    <Footer />
  </>
)
