import React from "react"

const HomePage = () => {
  return (
    <div className="section section-dark" id="home">
      <h1>Buy Neosurf Voucher Online – Australia & Worldwide</h1>
      <p id="shrinked-text">
        Neosurf is the most convenient e-payment method you’ll find online. No
        need for extra paperwork or revealing sensitive information you are not
        comfortable to share. All you have to do is go to your local store, buy
        neosurf vouchers and use them to pay online, for a variety of goods &
        services.
      </p>
      <p id="shrinked-text">
        <div className="easy123">
          <b>Easy as 1, 2, 3!</b>
        </div>
      </p>
      <a
        className="button"
        href="https://www.au-slots.com/?a_aid=5d5aaca3080a9&a_bid=1405c1db"
        target="_blank"
      >
        Buy Now
      </a>
    </div>
  )
}

export default HomePage
